<template>
    <div class="teacherDialog">
        <el-dialog :title="myTitle" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="teacherForm" ref="teacherDialogForm" :rules="teacherRules">
                <el-form-item label="工号"  prop="jobNo" :label-width="formLabelWidth">
                    <el-input v-model="teacherForm.jobNo" autocomplete="off" @input="jobNoChange"></el-input>
                </el-form-item>
                <el-form-item label="账号"  prop="name" :label-width="formLabelWidth">
                    <el-input v-model="teacherForm.name" autocomplete="off">
                    </el-input>
                </el-form-item>
                <el-form-item label="密码"  prop="pass" :label-width="formLabelWidth" v-if="teacherForm.id == null">
                    <el-input v-model="teacherForm.pass" type="password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="教师姓名"  prop="nickName" :label-width="formLabelWidth">
                    <el-input v-model="teacherForm.nickName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="学科"  prop="subject" :label-width="formLabelWidth">
                    <el-input v-model="teacherForm.subject" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注"  prop="remark" :label-width="formLabelWidth">
                    <el-input v-model="teacherForm.remark" type="textarea"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'teacherDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false,
                professionList: [],
                teacherForm:{ id: null, licenceId: null,  jobNo: null, name: null, pass: null, nickName: null, phoneNo: null,
                    schoolId: null, subject: null, remark: null,  },
                teacherRules: {
                    name: [{ required: true, message: '请输入账户名', trigger: 'blur' }],
                    jobNo: [{ required: true, message: '请输入工号', trigger: 'blur' }],
                    pass: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                    nickName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                    
                },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
	    },
        props: {
            teacher: {
                type: Object
            }
        },
        watch: {
            teacher: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.teacherForm = { id: n.id, licenceId: n.licence_id, jobNo: n.job_no, name: n.name, pass: null, nickName: n.nick_name,
                            schoolId: this.userInfo.schoolId, subject: n.subject, phoneNo: n.phone_no, remark: n.remark };
                        if(n.jobNo == null) this.teacherForm.jobNo =  (new Date()).getMilliseconds();
                        if(n.name == null) this.teacherForm.name =  this.userInfo.licenceKey + this.teacherForm.jobNo;
                        this.teacherForm.pass = this.userInfo.licenceKey + '123';
                        if(n.id == null) this.myTitle = '新建教师'; else this.myTitle = '编辑教师信息';
                    }else{
                        this.teacherForm = { id: null, licenceId: null,  jobNo: null, name: null, pass: null, nickName: null, phoneNo: null,
                            schoolId: null, subject: null, remark: null,};
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            jobNoChange(v){
                this.teacherForm.name =  this.userInfo.licenceKey + v;
            },
            submitForm(){
                console.log(this.teacherForm);
                let that = this;
                this.$refs.teacherDialogForm.validate((valid) => {
                    if(valid){
                        this.loading = true;
                        this.teacherForm.licenceId = this.userInfo.licenceId;
                        let param = { controllerName: 'sclTeacher', methodName: '/saveOrUpdate', param: this.teacherForm};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('teacher', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }else if(code == -3){
                                this.$message({message: '该教师已在系统中。。', type: 'error', duration: 5000});
                            }
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .teacherDialog{

    }


</style>


