<template>
    <div class="teacherList">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="工号、账号、姓名或学科"  @keyup.enter.native='loadTeacherList'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" size ="mini" icon="search" @click='loadTeacherList'>查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" @click='openTeacher(null)'>添加</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table_container">
            <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>
                <el-table-column prop="job_no" label="工号"  width="150" align='center' ></el-table-column>
                <el-table-column prop="name" label="账号"  width="150" align='center' ></el-table-column>
                <el-table-column prop="nick_name" label="姓名" align='center' ></el-table-column>
                <el-table-column prop="phone_no" label="联系方式" align='center' ></el-table-column>
                <el-table-column prop="school_name" label="所属单位" align='center' ></el-table-column>
                <el-table-column prop="subject" label="学科" align='center' ></el-table-column>
                <el-table-column prop="remark" label="备注" width="200" align='center' ></el-table-column>
                <el-table-column prop="operation" align='center' label="操作" width="300">
                    <template slot-scope='scope'>
                        <el-button icon='edit' size="mini" @click='openTeacher(scope.row)'>编辑</el-button>
                        <el-button type="warning" size="mini" @click='openTeacherPass(scope.row)'>更改密码</el-button>
                        <el-button type="danger" icon='edit' size="mini" @click='onDeleteTeacher(scope.row)'>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
        </div>
        <teacherDialog ref="teacherDialog" :teacher="selTeacher" @ok="loadTeacherList"></teacherDialog>
        <teacherPassDialog ref="teacherPassDialog" :teacherPass="selTeacher" @ok="loadTeacherList"></teacherPassDialog>
    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import teacherDialog from "./teacherDialog";
    import teacherPassDialog from "./teacherPassDialog";
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";

    export default {
        name: 'teacherList',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:true, isShow:false,
                professionList: [],
                selTeacher: null, selTeacherDtl: null,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, schoolId: null},
            }
        },
        components:{
            teacherDialog, teacherPassDialog, Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.loadTeacherList();
	   },
        methods: {

            loadTeacherList(){
                this.loading = true;

                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    schoolId: this.userInfo.schoolId, };
                let param = { controllerName: 'sclTeacher', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },

            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadTeacherList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadTeacherList()
            },
            onDeleteTeacher(row){
                let id = row.id
                let that = this;
                this.$confirm('此操作将永久删除该教师以及明细, 是否继续?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'sclTeacher', methodName: '/delete', param: { id: id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            that.loadTeacherList()
                        }
                        if (code == -3) {
                            that.$message({ type: 'error', message: '该教师下已关联有教学班，请先清除教学班后再删除！'});
                            return ;
                        }
                    });
                }).catch(() => {

                });
            },

            openTeacher(row){
                if(row != null){
                    this.selTeacher = row;
                }else{
                    this.selTeacher = { id: null, licenceId: null,  jobNo: null, name: null, pass: null, nickName: null, phoneNo: null,
                        schoolId: null, subject: null, remark: null, time: (new Date()).getMilliseconds()};
                }
                this.$refs.teacherDialog.showDialog();
            },
            openTeacherPass(row){
                if(row != null){
                    this.selTeacher =  { id: row.id, pass: null, passConfirm: null, };
                    this.$refs.teacherPassDialog.showDialog();
                }
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


